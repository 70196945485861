.App {
  height: 100%;
  transition: background-color 0.5s ease;
  overflow-y: auto;

  a {
    text-decoration: none;
    color: $black;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  img {
    background: rgba($grey, 0.05);
  }

  &--home {
    background: #fff url('img/home.jpg') center 5rem no-repeat;
    background-size: cover;
  }

  &--black {
    background: $black;

    a {
      color: $white;
    }
  }

  &__header {
    padding: 2.5rem;
    position: sticky;
    left: 0;
  }

  &__logo {
    display: block;
    margin: 0 0 1.5rem;

    transition: color 0.5s ease;

    font-size: 1.5rem;
    font-weight: 700;
  }

  &__nav {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 2em;

    &__item {
      opacity: 0.7;
      transition: opacity 0.5s ease, color 0.5s ease;

      &:hover,
      &:focus {
        opacity: 1;
      }
      &--active {
        opacity: 1;
        font-weight: 700;
      }
    }
  }
}
