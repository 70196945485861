.Listing {
  display: flex;
  list-style: none;
  margin: 2vh 0 0 15vh;
  padding: 0;

  &::after {
    content: '';
    padding: 0.5vh;
  }

  &__item {
    flex-shrink: 0;
    width: calc(55vh - 8.5rem);
    margin: 0 5vh 0 0;

    &:nth-child(odd) {
      margin-top: 5rem;
    }

    &--landscape {
      width: calc(80vh - 8rem);
    }

    img {
      max-width: 100%;
    }
  }
}
