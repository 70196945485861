.Lightbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;

  background: $black;
  transition: opacity 0.5s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    background: url(img/close.svg) center center no-repeat;
    width: 3.75rem;
    height: 3.75rem;
    text-indent: -9999em;
  }

  img {
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 10rem);
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 0 1.5rem 3rem;

    &__title {
      color: $white;
      margin: 0;
    }

    &__meta {
      color: $grey;
      margin: 0.25rem 0 0;
      font-size: 0.75rem;

      &__year {
        margin: 0 0.5rem 0 0;
      }
    }
  }
}
