$black: #1a1a1a;
$grey: #777;
$white: #fff;

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
}

@import 'app';
@import 'listing';
@import 'lightbox';
